import { z } from 'zod';
import { paymentPeriod } from './constants';

interface PrefixMap {
  [key: string]: string[];
}

export const validStartingCharacters = [
  'K',
  'J',
  'H',
  'G',
  'F',
  'E',
  'R',
  'P',
  'D',
  'N',
  'Q',
  'C',
  'M',
  'A',
  'L',
  'B',
  'k',
  'j',
  'h',
  'g',
  'f',
  'e',
  'r',
  'p',
  'd',
  'n',
  'q',
  'c',
  'm',
  'a',
  'l',
  'b'
];

const NETWORKS = ['MTN', 'VDF', 'ATL', 'TGO'] as const;

const phoneRegExp = /^\d{10}$/; // Matches 10 digits

const networkOptions = z.enum(NETWORKS, {
  required_error: 'Select a network',
});
const phoneSchema = z
  .string({ required_error: 'Phone number required' })
  .refine((value) => phoneRegExp.test(value), {
    message: 'Phone number must be 10 digits long',
  });

const validateNetworkMatch = (network: string, phone: string) => {
  const prefixMap: PrefixMap = {
    MTN: ['024', '025', '053', '054', '055', '059'],
    VDF: ['020', '050'],
    ATL: ['027', '057', '026', '056'],
  };
  const validPrefixes = prefixMap[network] || [];
  return validPrefixes.some((prefix: string) => phone.startsWith(prefix));
};

export const idSchema = z.string({ required_error: 'ID required' }).refine(
  (val) => {
    return (
      val.length >= 8
    );
  },
  {
    message: 'ID is invalid',
  },
);

export const validateIDSchema = z.object({
  id: idSchema,
  confirmid: idSchema
}).refine((data) => data.id === data.confirmid, {
  message: 'IDs do not match',
  path: ['confirmid']
})

export const CheckerSchema = z.object({
  id: idSchema
})

export const selfPaymentSchema = z
  .object({
    phonenumber: phoneSchema,
    rswitch: networkOptions,
    paymentmethod: z.enum(['momo', 'card'], {
      required_error: 'Select a payment option',
    }),
    id: idSchema,
    confirmId: idSchema,
    amount: z
      .string({
        required_error: 'Select a payment period',
      })
      .refine((val) => paymentPeriod.some((period) => period.value === val), {
        message: 'Please select a valid period range',
      }),
  })
  .superRefine((data, ctx) => {
    if (!validateNetworkMatch(data.rswitch, data.phonenumber)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Number and network don't match",
        path: ['phonenumber'],
      });
    }
  });

export const groupPaymentSchema = z
  .object({
    phonenumber: phoneSchema,
    rswitch: networkOptions,
    paymentmethod: z.enum(['momo', 'card'], {
      required_error: 'Please select a payment option',
    }),
    id: idSchema,
    confirmId: idSchema,
    amount: z
      .string({
        required_error: "Please select how many months you're paying for",
      })
      .refine((val) => paymentPeriod.some((period) => period.value === val), {
        message: 'Please select a valid period range',
      }),
    member_ids: z
      .string()
      .refine((val) => val.length !== 11, {
        message: 'Please provide a valid ID',
      })
      .optional(),
  })
  .superRefine((data, ctx) => {
    if (!validateNetworkMatch(data.rswitch, data.phonenumber)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Number oes not match seledcted network',
        path: ['phonenumber'],
      });
    }
  });

export const verifySchema = z.object({
  otp: z.string({
    required_error: 'Please enter your OTP to proceed',
  }),
});
