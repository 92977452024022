import { cn } from "@/lib/utils";
import { Link, useLocation } from "react-router-dom";

const headerNav = [
  {
    name: "Make Payment",
    path: "/"
  },
  {
    name: "Check Payment",
    path: "/dues-checker"
  }
];

export default function StickyHeader() {
  const location = useLocation();
  return (
    <div className="mb-3 mt-4 flex w-full flex-row items-center justify-between px-4 mx-auto top-0 sm:w-[380px] fixed bg-white rounded-2xl py-2 z-[999999] shadow-lg">
      <div className="h-10 w-10">
        <Link to='/' className="hover:pointer-cursor">
          <img
            src="/logo_main.png"
            className="h-auto w-full"
            alt="NDC Dues Payment Platform"
          />
        </Link>
      </div>
      <div className='flex flex-row items-center gap-2 md:gap-4'>
        {headerNav.map((nav) => (
          <Link
            key={nav.name}
            to={nav.path}
            className={cn('text-ndcgreen text-sm uppercase font-semibold', {
              "text-ndcred underline-offset-4 underline": nav.path === location.pathname
            })}
          >
            {nav.name}
          </Link>
        ))}
      </div>
    </div>
  )
}