import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useEffect, useState } from 'react';
import {
  type PaymentPayload,
  useMakePayment,
  useSendBulkPayments,
  useSendOTP,
} from '@/services/dues';
import { useSearchParams } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import {
  useCheckoutResponseStore,
  useFormStore,
  useValidUserStore,
} from '@/stores/rootStore';
import SelfPaymentForm from '@/components/forms/SelfPaymentForm';
import GroupPaymentForm from '@/components/forms/GroupPaymentForm';
import type { z } from 'zod';
import type {
  groupPaymentSchema,
  selfPaymentSchema,
  verifySchema,
} from '@/lib/schemas';
import { generateRandom12DigitValue } from '@/lib/utils';
import OTPVerifyForm from '@/components/forms/OTPVerifyForm';
import { useRegisterMember } from '@/services/register';
import { toast } from 'sonner';
import { ShieldCheck, BookmarkX, X } from 'lucide-react';
import StickyHeader from '@/components/home/StickyHeader';
import CopyrightFooter from '@/components/home/CopyrightFooter';
import UssdInfo from '@/components/home/UssdInfo';

export default function Dues() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [toastShown, setToastShown] = useState(false);
  const [queryParams] = useState(Object.fromEntries(searchParams));
  const [otpInvalid, setOtpInvalid] = useState(false);
  const [openOTPAlert, setOpenOTPAlert] = useState(false);
  const { formData, resetFormData, monthsToPay, paymentType, updateFormData } =
    useFormStore((state) => state);
  const { resetCheckoutResponse } = useCheckoutResponseStore((state) => state);
  const { selectedMembers, resetVaildUserData, balanceAvailability, idExists } =
    useValidUserStore((state) => state);

  const { mutate: makePmtMutation, status: makePaymentStatus } =
    useMakePayment();
  const { mutate: sendOtpMutation, isPending: sendOtpPending } = useSendOTP();

  const { mutate: bulkPaymentMutation, isPending: bulkPaymentPending } =
    useSendBulkPayments();
  const { mutate: registerMemberMutate, isPending: registerMemberPending } =
    useRegisterMember();

  const verifyOTP = (values: z.infer<typeof verifySchema>) => {
    if (values.otp.length < 6 || values.otp.length > 6) {
      setOtpInvalid(true);
      return;
    }
    const otpValue = Number.parseInt(values.otp);
    if (paymentType === 'group' && balanceAvailability) {
      processBulkPayments();
    } else if (paymentType === 'group' && !balanceAvailability) {
      const actualAmount = selectedMembers
        ? Number.parseInt(formData?.amount ?? '0') * selectedMembers.length
        : Number.parseInt(formData?.amount ?? '0');
      const paymentPayload: PaymentPayload = {
        amount: actualAmount,
        transactionid: generateRandom12DigitValue(),
        memberid: formData?.id ?? '',
        subscribernumber: formData?.phonenumber ?? '',
        otp: otpValue,
        rswitch: formData?.rswitch ?? '',
        paymentmethod: formData?.paymentmethod ?? '',
        desc: `NDC Dues Payment - ${formData?.id}`,
      };
      makePmtMutation(paymentPayload, {
        onSuccess: (response) => {
          setOpenOTPAlert(false);
          if (response === null) {
            resetCheckoutResponse();
            resetFormData();
            resetVaildUserData();
            toast.success('Done', {
              description:
                'Please check your phone to complete the transaction',
              icon: <ShieldCheck size={16} />,
              position: 'top-center',
              duration: 6000,
              onDismiss: () => window.location.reload(),
              onAutoClose: () => window.location.reload(),
            });
          } else {
            window.localStorage.setItem(
              'redirected',
              JSON.stringify({ didRedirect: true }),
            );
            window.location.href = response.checkout_url;
          }
        },
        onError: () => {
          toast.error('Error', {
            description: 'We encountered a problem. Please try again',
            icon: <X size={16} />,
            position: 'top-center',
            duration: 6000,
            onDismiss: () => window.location.reload(),
            onAutoClose: () => window.location.reload(),
          });
        },
      });
    } else {
      const paymentPayload: PaymentPayload = {
        amount: Number(formData?.amount),
        transactionid: generateRandom12DigitValue(),
        memberid: formData?.id ?? '',
        subscribernumber: formData?.phonenumber ?? '',
        otp: otpValue,
        rswitch: formData?.rswitch ?? '',
        paymentmethod: formData?.paymentmethod ?? '',
        desc: `NDC Dues Payment - ${formData?.id}`,
      };
      makePmtMutation(paymentPayload, {
        onSuccess: (response) => {
          setOpenOTPAlert(false);
          if (response === null) {
            resetCheckoutResponse();
            resetFormData();
            resetVaildUserData();
            toast.success('Done', {
              description:
                'Please check your phone to complete the transaction',
              icon: <ShieldCheck size={16} />,
              position: 'top-center',
              duration: 6000,
              onDismiss: () => window.location.reload(),
              onAutoClose: () => window.location.reload(),
            });
          } else {
            window.localStorage.setItem(
              'redirected',
              JSON.stringify({ didRedirect: true }),
            );
            window.location.href = response.checkout_url;
          }
        },
        onError: () => {
          toast.error('Error', {
            description: 'We encountered a problem. Please try again',
            icon: <X size={16} />,
            position: 'top-center',
            duration: 6000,
            onDismiss: () => window.location.reload(),
            onAutoClose: () => window.location.reload(),
          });
        },
      });
    }
  };

  const submitFormData = async (
    values:
      | z.infer<typeof selfPaymentSchema>
      | z.infer<typeof groupPaymentSchema>,
  ) => {
    updateFormData(values);
    if (!idExists && paymentType === 'self') {
      registerMemberMutate(
        { id: values.id, phonenumber: values.phonenumber },
        {
          onSuccess: () => {
            sendOtpMutation(
              { phone: values.phonenumber, service: 'dues' },
              {
                onSuccess: () => {
                  setOpenOTPAlert(true);
                },
                onError: () => {
                  console.error('Error occurred');
                },
              },
            );
          },
          onError: () => {
            // console.error('Error occurred', error);
            toast.error('Error', {
              description:
                'An error occurred. Please try again by clicking the Make Payment button',
              icon: <BookmarkX size={16} />,
              position: 'top-center',
            });
          },
        },
      );
    } else {
      sendOtpMutation(
        { phone: values.phonenumber, service: 'dues' },
        {
          onSuccess: () => {
            setOpenOTPAlert(true);
          },
          onError: () => {
            // console.error('Error occurred');
            toast.error('Error', {
              description:
                'An error occurred. Please try again by clicking the Make Payment button',
              icon: <BookmarkX size={16} />,
              position: 'top-center',
            });
          },
        },
      );
    }
  };

  const processBulkPayments = () => {
    const payload = {
      from: formData?.id ?? '',
      to: selectedMembers?.join(',') ?? '',
      unitamount: parseInt(formData?.amount ?? '0'),
    };
    bulkPaymentMutation(payload, {
      onSuccess: () => {
        setOpenOTPAlert(false);
        resetCheckoutResponse();
        resetFormData();
        resetVaildUserData();
        const paidForMembers = selectedMembers?.join(', ');
        toast.success('Success', {
          description: `Congratulations! You have successfully paid ${monthsToPay} dues
                for Members{' '}
                <b>
                  ${paidForMembers}
                </b>
                .`,
          position: 'top-center',
          duration: 8000,
          onAutoClose: () => {
            window.localStorage.clear();
          },
        });
      },
      onError: () => {
        setOpenOTPAlert(false);
        resetCheckoutResponse();
        resetFormData();
        resetVaildUserData();
        const paidForMembers = selectedMembers?.join(', ');
        toast.error('Error', {
          description: `Sorry! We could not process your payment for Members{' '}
                <b>
                  ${paidForMembers}
                </b>
                .`,
          position: 'top-center',
          duration: 8000,
          onAutoClose: () => {
            window.localStorage.clear();
          },
        });
      },
    });
  };

  useEffect(() => {
    const trxStatus = queryParams.status;
    const trxId = queryParams.transaction_id;
    if (Object.keys(queryParams).length > 0 && trxStatus) {
      if (!toastShown) {
        if (trxStatus === 'cancelled') {
          setTimeout(() => {
            toast.error('Error', {
              description: `There was an error processing the request. Please report this to your branch manager, and refer to your transaction id: <b>${trxId}</b>`,
              position: 'top-center',
              duration: 8000,
              onAutoClose: () => {
                setSearchParams({});
              },
            });
            setToastShown(true);
          }, 100);
        }
        if (trxStatus === 'approved' || trxStatus === 'successful') {
          // console.log('render success toast');
          setTimeout(() => {
            toast.success('Success', {
              description: `Transaction with ID: <b>${trxId}</b> was successful. Please check your phone for confirmation`,
              position: 'top-center',
              duration: 8000,
              onAutoClose: () => {
                setSearchParams({});
              },
            });
            setToastShown(true);
          }, 100);
        }
      }
      // toast.success('queryParams')
    }

    return () => {
      toast.dismiss();
    };
  }, [queryParams, setSearchParams, toastShown]);

  return (
    <div className="dues-bg flex min-h-screen w-screen flex-col items-center justify-start overflow-y-auto text-white bg-blend-overlay relative">
      <StickyHeader />
      <div className="flex w-full flex-col items-center justify-start md:mx-auto sm:w-[420px] md:px-6 mt-20">
        <h1 className="flex pb-2 pt-4 text-center text-3xl font-semibold leading-tight tracking-wide text-ndc-light-green">
          Pay your dues here
        </h1>
        {/* <DuesHero /> */}
        <div className="mx-auto mb-5 mt-2 flex h-auto w-full flex-col rounded-2xl bg-white pb-4">
          <Tabs defaultValue="selfPayment" className="h-full w-full">
            <TabsList className="w-full">
              <TabsTrigger
                value="selfPayment"
                className="w-1/2 rounded-tl-2xl shadow-none data-[state=inactive]:rounded-br-2xl"
              >
                My Payment
              </TabsTrigger>
              <TabsTrigger
                value="groupPayment"
                className="w-1/2 rounded-tr-2xl shadow-none data-[state=inactive]:rounded-bl-2xl"
              >
                Group Payment
              </TabsTrigger>
            </TabsList>
            <TabsContent value="selfPayment" className="mb-4 h-full lg:mb-0">
              <SelfPaymentForm
                pending={registerMemberPending || sendOtpPending}
                submitAction={submitFormData}
              />
            </TabsContent>
            <TabsContent value="groupPayment" className="mb-4 h-full lg:mb-0">
              <GroupPaymentForm
                submitAction={submitFormData}
                submitPending={
                  sendOtpPending ||
                  makePaymentStatus === 'pending' ||
                  bulkPaymentPending
                }
              />
            </TabsContent>
          </Tabs>
        </div>
        {/* <Button onClick={() => toast.success('message')}>Show toast</Button> */}
      </div>

      {/* OTP Alert open={openOTPAlert} */}
      <AlertDialog open={openOTPAlert} onOpenChange={setOpenOTPAlert}>
        <AlertDialogContent className="flex flex-col items-center">
          <AlertDialogHeader>
            <AlertDialogTitle className="text-center">
              Secure your Payment with OTP
            </AlertDialogTitle>
            <AlertDialogDescription className="text-center">
              Enter the OTP you received on your phone. We will confirm this
              before proceeding with payment
            </AlertDialogDescription>
          </AlertDialogHeader>
          <div className="flex w-full flex-col items-center">
            <OTPVerifyForm
              confirmOTP={verifyOTP}
              pendingState={
                makePaymentStatus === 'pending' || bulkPaymentPending
              }
              otpInvalid={otpInvalid}
              setOtpInvalid={setOtpInvalid}
            />
          </div>
        </AlertDialogContent>
      </AlertDialog>

      {/* Show button drawer on screens smaller than medium */}
      <UssdInfo />
      <CopyrightFooter />
    </div>
  );
}
