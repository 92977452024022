export default function UssdInfo() {
  return (
    <div className="flex h-auto w-full flex-col pb-4 sm:w-[420px] px-2">
      <h3 className="mt-3 flex w-full flex-col items-center justify-center font-bold uppercase sm:w-[420px]">
        <span>OR</span>
        <span className="text-3xl">Pay via USSD</span>
      </h3>
      <p className="mx-auto mt-3 w-[300px] rounded-2xl bg-ndc-red-alt px-2 py-3 text-6xl font-semibold text-ndc-light-green md:w-[345px] md:text-7xl">
        *920*25#
      </p>
      <div className="my-4" />
      <p className="text-center text-base font-light leading-normal text-zinc-200">
        The National Democratic Congress is built on individuals giving what
        they can, so together we can do something extraordinary. Your dues
        mean a lot to the NDC's course and together we can steer a positive
        change within the Party and Ghana.
      </p>
    </div>
  )
}