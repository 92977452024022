import { Link } from "react-router-dom";

export default function CopyrightFooter() {
   return (
     <div className="mb-3 mt-auto flex w-full flex-col px-4 pt-6 md:mx-auto sm:w-10/12 md:px-6 lg:mt-auto lg:flex-row lg:items-center lg:justify-between">
       <p className="pl-4 text-sm text-white">
         &copy;2023 NDCPayment All rights reserved.
       </p>
       <div className="flex flex-row justify-between space-x-2 px-4">
         <Link to={'/'} className="text-sm text-white">
           Privacy Policy
         </Link>
         <Link to={'/'} className="text-sm text-white">
           Terms of Service
         </Link>
       </div>
     </div>
   )
}