import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import dayjs from "dayjs";
// import { Separator } from "@/components/ui/separator";
// import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { CheckerSchema } from "@/lib/schemas";
import { request } from "@/utilities/core_request";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import type { z } from "zod";
import { Loader } from "lucide-react";
import StickyHeader from "@/components/home/StickyHeader";
import ReCAPTCHA from "react-google-recaptcha";
import { verifyRecaptcha } from "@/services/dues";
import CopyrightFooter from "@/components/home/CopyrightFooter";


export interface PaymentInfo {
  memberid: string
  paid: boolean
  paidforinmonths: number
}


export default function DuesChecker() {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo[] | null>(null)
  const [isPending, setIsPending] = useState(false)
  const [missingInfo, setMissingInfo] = useState(false)
  const checkerForm = useForm<z.infer<typeof CheckerSchema>>({
    resolver: zodResolver(CheckerSchema),
    defaultValues: {
      id: ""
    }
  })
  const submitRequest = async (reqVal: z.infer<typeof CheckerSchema>) => {
    setIsPending(true)
    try {
      const captchaToken = await recaptchaRef.current?.executeAsync();
      if (!captchaToken) {
        toast.error('Error', {
          description: 'Recaptcha is invalid. Please try again'
        })
      } else {
        const data = await verifyRecaptcha(captchaToken)
        if (data.success) {
          const response = await request<PaymentInfo[]>(`${import.meta.env.VITE_API_URL}/dues/info`, {
            headers: {
              "Content-Type": "application/json"
            },
            method: "POST",
            body: {
              memberid: reqVal.id.trim()
            }
          })
          if (!response) {
            setMissingInfo(true)
            toast.error(`No payment info for ${reqVal.id}`)
          }
          if (response.length === 0) {
            setMissingInfo(true)
          }
          setPaymentInfo(response)
        } else {
          toast.error('Error', {
            description: 'Recaptcha is invalid. Please try again'
          })
        }
      }

    } catch (error) {
      console.error(error)
      toast.error("Unable to process request. Please try again");
    } finally {
      setIsPending(false)
    }

  }

  const displayDueDate = (value: number): string => {
    if (value === 0) {
      return dayjs().add(1, 'month').format('DD-MM-YYYY')
    }
    const projectedDate = dayjs().add(value, 'month');
    return projectedDate.format('DD-MM-YYYY')
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      checkerForm.handleSubmit(submitRequest)
    }
  }
  return (
    <div className="dues-bg flex min-h-screen w-screen flex-col items-center justify-start overflow-y-auto bg-blend-overlay relative">
      <StickyHeader />

      <div className="flex w-full flex-col items-center justify-start md:mx-auto sm:w-[420px] md:px-6 mt-20">
        <h1 className="flex pb-2 pt-4 text-center text-3xl font-semibold leading-tight tracking-wide text-ndc-light-green mb-4">
          Member Dues Checker
        </h1>

        <div className="w-full p-4 bg-white rounded-lg shadow-lg shadow-gray-800">
          <h2 className="text-xl font-medium tracking-wide">Check Dues Payment</h2>
          <p className="mb-6 text-xs">Enter the Member ID to see payment information</p>
          {/* form widget */}
          <Form {...checkerForm}>
            <form className="space-y-6 mb-6" onSubmit={checkerForm.handleSubmit(submitRequest)} onChange={() => {
              if (missingInfo) {
                setMissingInfo(false)
              }
              setPaymentInfo(null)
            }}>
              <div className="flex space-x-2">
                <FormField
                  name="id"
                  control={checkerForm.control}
                  render={({ field }) => (
                    <FormItem className="flex-grow">
                      <FormControl>
                        <Input
                          {...field}
                          type="text"
                          placeholder="Member ID"
                          className="flex-grow"
                          onKeyDown={handleKeyPress}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button type="submit" variant="default" disabled={isPending} className="w-20">
                  {isPending ? <Loader className="animate-spin" /> : 'Submit'}
                </Button>
              </div>
              <ReCAPTCHA
                ref={recaptchaRef}
                size='invisible'
                sitekey={import.meta.env.VITE_RECAPTCHA}
              />
            </form>
          </Form>

          {/* Results widget */}
          {paymentInfo !== null && (
            <div className="space-y-6">
              {paymentInfo && paymentInfo.length > 0 && (
                <div className="flex flex-col gap-4">
                  <div>
                    <h3 className="text-lg font-semibold mb-2">Dues Status</h3>
                    <p className="text-gray-600">
                      Dues paid up to <code>{displayDueDate(paymentInfo[0].paidforinmonths)}</code>
                    </p>
                  </div>
                  {/* <Separator />
                  <div>
                    <h3 className="text-lg font-semibold mb-2">Payment History</h3>
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead>Date</TableHead>
                          <TableHead>Amount</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {paymentInfo.lastPayments.map((payment, index) => (
                          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                          <TableRow key={index}>
                            <TableCell>{payment.date}</TableCell>
                            <TableCell>GHC{payment.amount.toFixed(2)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div> */}
                </div>
              )}
              {missingInfo && (<p className="text-red-500">No payment information found for this member ID. Check ID and try again</p>)}
            </div>
          )}

        </div>
      </div>

      {/* Show button drawer on screens smaller than medium */}

      <CopyrightFooter />

    </div>
  )
}